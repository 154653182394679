import { styled } from "@mui/material";
import { breakpoint } from "../Breakpoints/breakpoints";

export const DeliverableIframe = styled("iframe")`
  min-width: 100%;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  border: 0px;
  position: relative;

  ${breakpoint(
    "medium-",
    `
      padding: 0px 30px; 
       `
  )}

  ${breakpoint("large-", `padding: 0px 20px;`)}
`;

export const NoScrollContainer = styled("div")`
  height: calc(100vh); //vh - nav height
  font-family: Helvetica;
  font-weight: 300;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  ${breakpoint(
    "medium+",
    `
    padding: 0 10%;
  `
  )}

  ${breakpoint(
    "medium-",
    `
      height: auto;
    `
  )}
`;

export const IframeWrapper = styled("div")`
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  height: 85%;
  width: 100%;
  flex-shrink: 1;
`;

export const HeaderTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  > p {
    margin: 0;
  }
`